// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-anfrage-gesendet-jsx": () => import("./../../../src/pages/anfrage-gesendet.jsx" /* webpackChunkName: "component---src-pages-anfrage-gesendet-jsx" */),
  "component---src-pages-anfragen-jsx": () => import("./../../../src/pages/anfragen.jsx" /* webpackChunkName: "component---src-pages-anfragen-jsx" */),
  "component---src-pages-blog-blog-title-slug-jsx": () => import("./../../../src/pages/blog/{Blog.title_slug}.jsx" /* webpackChunkName: "component---src-pages-blog-blog-title-slug-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-umsatz-rechner-jsx": () => import("./../../../src/pages/umsatz-rechner.jsx" /* webpackChunkName: "component---src-pages-umsatz-rechner-jsx" */)
}

